<template>
	<div>
		<el-dialog
			:title="titleDialog"
			:visible="showDialog"
			@open="create"
			width="50%"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
		>
			<div class="row" v-if="generate && loaded">
				<div class="col-lg-12">
					<div class="form-group">
						<label class="control-label">Cliente</label>
						<el-select
							v-model="document.customer_id"
							filterable
							remote
							popper-class="el-select-customers"
							dusk="customer_id"
							placeholder="Buscar..."
							:remote-method="searchRemoteCustomers"
							@change="changeCustomer"
							:loading="loading_search"
						>
							<el-option
								v-for="option in customers"
								:key="option.id"
								:value="option.id"
								:label="option.description"
							></el-option>
						</el-select>
						<small
							class="form-control-feedback"
							v-if="errors.customer_id"
							v-text="errors.customer_id[0]"
						></small>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="form-group" :class="{ 'has-danger': errors.date_of_issue }">
						<label class="control-label">Fecha de emisión</label>
						<el-date-picker
							v-model="document.date_of_issue"
							type="date"
							value-format="yyyy-MM-dd"
							format="dd/MM/yyyy"
							:clearable="false"
							:picker-options="pickerOptions"
							@change="changeDateOfIssue"
						></el-date-picker>
						<small
							class="form-control-feedback"
							v-if="errors.date_of_issue"
							v-text="errors.date_of_issue[0]"
						></small>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="form-group" :class="{ 'has-danger': errors.date_of_issue }">
						<label class="control-label">Fecha de vencimiento</label>
						<el-date-picker
							v-model="document.date_of_due"
							type="date"
							value-format="yyyy-MM-dd"
							format="dd/MM/yyyy"
							:clearable="false"
						></el-date-picker>
						<small
							class="form-control-feedback"
							v-if="errors.date_of_due"
							v-text="errors.date_of_due[0]"
						></small>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="form-group" :class="{ 'has-danger': errors.document_type_id }">
						<label class="control-label">Tipo comprobante</label>
						<el-select
							v-model="document.document_type_id"
							@change="changeDocumentType"
							popper-class="el-select-document_type"
							dusk="document_type_id"
							class="border-left rounded-left border-info"
						>
							<el-option
								v-for="option in document_types"
								:key="option.id"
								:value="option.id"
								:label="option.description"
							></el-option>
						</el-select>
						<small
							class="form-control-feedback"
							v-if="errors.document_type_id"
							v-text="errors.document_type_id[0]"
						></small>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group" :class="{ 'has-danger': errors.series_id }">
						<label class="control-label">Serie</label>
						<el-select v-model="document.series_id">
							<el-option
								v-for="option in series"
								:key="option.id"
								:value="option.id"
								:label="option.number"
							></el-option>
						</el-select>
						<small
							class="form-control-feedback"
							v-if="errors.series_id"
							v-text="errors.series_id[0]"
						></small>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<template v-if="showClose">
					<el-button @click="clickClose">Cerrar</el-button>
					<el-button
						class="submit"
						type="primary"
						@click="submit"
						:loading="loading_submit"
						v-if="generate && flag_generate"
					>
						<template v-if="loading_submit">
							Generando...
						</template>
						<template v-else>
							Generar
						</template>
					</el-button>
				</template>
				<template v-else>
					<el-button
						class="submit"
						type="primary"
						plain
						@click="submit"
						:loading="loading_submit"
						v-if="generate && flag_generate"
					>
						<template v-if="loading_submit">
							Generando...
						</template>
						<template v-else>
							Generar Comprobante
						</template>
					</el-button>
					<el-button @click="clickFinalize" v-else>Ir al listado</el-button>
					<el-button type="primary" @click="clickNewCommand">Nueva cotización</el-button>
				</template>
			</span>
		</el-dialog>

		<document-options
			:showDialog.sync="showDialogDocumentOptions"
			:recordId="documentNewId"
			:isContingency="false"
			:showClose="true"
		></document-options>
	</div>
</template>

<script>
import DocumentOptions from 'Views/Admin/Documents/Component/Options.vue';
import filter from 'lodash/filter';
import find from 'lodash/find';
import dayjs from 'dayjs';

export default {
	components: { DocumentOptions },

	props: ['showDialog', 'recordId', 'showClose', 'showGenerate'],
	data() {
		return {
			titleDialog: null,
			loading: false,
			resource: 'commands',
			resource_doc: 'documents',
			errors: {},
			form: {},
			document: {},
			document_types: [],
			all_document_types: [],
			all_series: [],
			series: [],
			customers: [],
			generate: false,
			loading_submit: false,
			showDialogDocumentOptions: false,
			documentNewId: null,
			loading_search: false,
			flag_generate: true,
			loaded: false,
			pickerOptions: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') < time;
				},
			},
		};
	},
	created() {
		//
	},
	methods: {
		initForm() {
			this.generate = this.showGenerate ? true : false;
			this.flag_generate = true;
			this.errors = {};
			this.form = {
				id: null,
				external_id: null,
				identifier: null,
				date_of_issue: null,
				command: null,
			};
		},
		getCustomer() {
			this.$http
				.get(`/${this.resource_doc}/search/customer/${this.form.command.customer_id}`)
				.then((response) => {
					this.customers = response.data;
					this.document.customer_id = this.form.command.customer_id;
					this.changeCustomer();
				});
		},
		changeCustomer() {
			this.validateIdentityDocumentType();
		},
		searchRemoteCustomers(input) {
			if (input.length > 0) {
				this.loading_search = true;
				let parameters = `input=${input}&document_type_id=${this.document.document_type_id}&operation_type_id=${this.document.operation_type_id}`;
				this.$http
					.get(`/${this.resource_doc}/search/customers?${parameters}`)
					.then((response) => {
						this.customers = response.data;
						this.loading_search = false;
					});
			}
		},
		initDocument() {
			this.document = {
				document_type_id: null,
				series_id: null,
				establishment_id: null,
				warehouse_id: null,
				number: '#',
				date_of_issue: dayjs().format('YYYY-MM-DD'),
				time_of_issue: null,
				customer_id: null,
				currency_type_id: null,
				payment_condition_type_id: '1',
				purchase_order: null,
				exchange_rate_sale: 0,
				total_prepayment: 0,
				total_charge: 0,
				total_discount: 0,
				total_exportation: 0,
				total_free: 0,
				total_taxed: 0,
				total_unaffected: 0,
				total_exonerated: 0,
				total_igv: 0,
				total_base_isc: 0,
				total_isc: 0,
				total_base_other_taxes: 0,
				total_other_taxes: 0,
				total_taxes: 0,
				total_value: 0,
				total: 0,
				operation_type_id: null,
				date_of_due: dayjs().format('YYYY-MM-DD'),
				items: [],
				charges: [],
				discount: {},
				attributes: [],
				guides: [],
				additional_information: null,
				actions: {
					format_pdf: 'a4',
				},
				command_id: null,
			};
		},
		changeDateOfIssue() {
			this.document.date_of_due = this.document.date_of_issue;
		},
		resetDocument() {
			this.generate = this.showGenerate ? true : false;
			this.initDocument();
			this.document.document_type_id =
				this.document_types.length > 0 ? this.document_types[0].id : null;
			this.changeDocumentType();
		},
		submit() {
			this.loading_submit = true;
			this.assignDocument();

			this.document.prefix = null;

			if (!this.document.series_id) {
				return this.$message.error(
					'El comprobnate seleccionado de no tiene serie, asignar para continuar'
				);
			}

			if (
				this.form.document_type_id == '01' &&
				this.document.customer.identity_document_type_id != '6'
			) {
				return this.$message.error(
					'Error! no se puede emitir una factura, el cliente no es válido.'
				);
			}

			this.$http
				.post(`/${this.resource_doc}`, this.document)
				.then((response) => {
					if (response.data.success) {
						this.documentNewId = response.data.data.id;
						this.showDialogDocumentOptions = true;

						this.flag_generate = false;
						this.$eventHub.$emit('reloadData', this.resource);
						this.resetDocument();
						this.document.customer_id = this.form.command.customer_id;
						this.changeCustomer();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loading_submit = false;
				});
		},
		assignDocument() {
			let q = this.form.command;
			this.document.command_id = q.id;
			this.document.establishment_id = q.establishment_id;
			this.document.warehouse_id = q.warehouse_id;
			// this.document.date_of_issue = q.date_of_issue
			this.document.time_of_issue = dayjs().format('HH:mm:ss');
			// this.document.customer_id = q.customer_id
			this.document.customer = q.customer;
			this.document.payment_condition_type_id = '1';
			this.document.currency_type_id = q.currency_type_id;
			this.document.purchase_order = null;
			this.document.exchange_rate_sale = q.exchange_rate_sale;
			this.document.total_prepayment = q.total_prepayment;
			this.document.total_charge = q.total_charge;
			this.document.total_discount = q.total_discount;
			this.document.total_exportation = q.total_exportation;
			this.document.total_free = q.total_free;
			this.document.total_taxed = q.total_taxed;
			this.document.total_unaffected = q.total_unaffected;
			this.document.total_exonerated = q.total_exonerated;
			this.document.total_igv = q.total_igv;
			this.document.total_base_isc = q.total_base_isc;
			this.document.total_isc = q.total_isc;
			this.document.total_base_other_taxes = q.total_base_other_taxes;
			this.document.total_other_taxes = q.total_other_taxes;
			this.document.total_taxes = q.total_taxes;
			this.document.total_value = q.total_value;
			this.document.total = q.total;
			this.document.operation_type_id = '0101';
			// this.document.date_of_due = q.date_of_issue
			this.document.items = q.items;
			this.document.charges = q.charges;
			this.document.discount = q.discount;
			this.document.attributes = [];
			this.document.payments = [];
			this.document.guides = q.guides;
			this.document.additional_information = null;
			this.document.actions = {
				format_pdf: 'ticket',
			};
		},
		async create() {
			await this.initForm();
			await this.$http.get(`/${this.resource}/option/tables`).then((response) => {
				this.all_document_types = response.data.document_types;
				this.all_series = response.data.series;
			});
			await this.initDocument();
			await this.getRecord();

			this.loaded = true;
		},
		async getRecord() {
			await this.$http.get(`/${this.resource}/record/${this.recordId}`).then((response) => {
				this.form = response.data.data;
				this.getCustomer();
				this.titleDialog = `Comanda N°: ` + this.form.identifier;
			});
		},
		changeDocumentType() {
			this.series = [];

			this.filterSeries();
		},
		async validateIdentityDocumentType() {
			let identity_document_types = ['0', '1'];
			let customer = find(this.customers, { id: this.document.customer_id });

			if (identity_document_types.includes(customer.identity_document_type_id)) {
				this.document_types = filter(this.all_document_types, { id: '03' });
			} else {
				this.document_types = this.all_document_types;
			}

			this.document.document_type_id =
				this.document_types.length > 0 ? this.document_types[0].id : null;
			await this.changeDocumentType();
		},
		filterSeries() {
			this.document.series_id = null;
			this.series = filter(this.all_series, {
				document_type_id: this.document.document_type_id,
			});
			this.document.series_id = this.series.length > 0 ? this.series[0].id : null;
		},
		clickFinalize() {
			location.href = `/${this.resource}`;
		},
		clickNewCommand() {
			this.clickClose();
		},
		clickClose() {
			this.$emit('update:showDialog', false);
			this.initForm();
			this.resetDocument();
		},
	},
};
</script>
